import { Canvas } from "@react-three/fiber";
import React, {
  FC,
  RefObject,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { sRGBEncoding } from "three";
import "./App.scss";
import { Area } from "./components/Area/Area";

import { Helmet } from "react-helmet";

interface CanvasProps {
  containerRef: RefObject<HTMLDivElement>;
}

const ComingSoon: FC<CanvasProps> = ({ containerRef }) => {
  const [containerWidth, setContainerWidth] = useState(window.outerWidth);
  const [containerHeight, setContainerHeight] = useState(window.outerHeight);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [containerRef]);

  return (
    <Canvas
      gl={{
        pixelRatio: Math.min(window.devicePixelRatio, 2),
        outputEncoding: sRGBEncoding,
      }}
      camera={{
        fov: 65,
        near: containerWidth / containerHeight,
        far: 10000,
        position: [0, 0, 100],
      }}
    >
      <Suspense fallback={null}>
        <Area></Area>
      </Suspense>
    </Canvas>
  );
};

function App() {
  const canvasContainer = useRef<HTMLDivElement>(null);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sovaj Studio</title>
        <meta
          name="description"
          content="Studio de création d’univers et d’interfaces numériques disruptifs à l’image de nos clients."
        />
      </Helmet>

      <div className="App">
        <div id="playground" ref={canvasContainer}>
          <ComingSoon containerRef={canvasContainer}></ComingSoon>
        </div>
        <div className="presentation">
          <div className="presentation__text">
            <h1>Sovaj Studio</h1>
            <code className="presentation__lead">
              <address>128 rue de la boétie, Paris 8</address>
            </code>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
