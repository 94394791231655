import { FC, useLayoutEffect, useMemo, useRef } from "react";
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Path,
  Shape,
  ShapeGeometry,
  Texture,
  Vector2,
  Vector3,
} from "three";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { TextParticlesConfig } from "../../types";

type ShapeOrPath = Shape | Path;

interface TextProps {
  font: Font;
  particle: Texture;
  color: Color;
  data: TextParticlesConfig;
}

const Text: FC<TextProps> = ({ font, particle, color, data }) => {
  const textBufferGeometryRef = useRef<BufferGeometry>(null);
  const thePoints: Vector3[] = [];

  // 1.
  const shapes: Shape[] = useMemo(
    () => font.generateShapes(data.text, data.textSize),
    [data, font]
  );
  const geometry = useMemo(() => new ShapeGeometry(shapes), [shapes]);
  geometry.computeBoundingBox();

  const xMid =
    -0.5 * (geometry.boundingBox!.max.x - geometry.boundingBox!.min.x);
  const yMid =
    (geometry.boundingBox!.max.y - geometry.boundingBox!.min.y) / 2.85;
  geometry.center();

  // 2.
  let holeShapes: Path[] = [];
  for (let q = 0; q < shapes.length; q++) {
    let shape = shapes[q];

    if (shape.holes && shape.holes.length > 0) {
      for (let j = 0; j < shape.holes.length; j++) {
        let hole = shape.holes[j];
        holeShapes.push(hole);
      }
    }
  }
  const allShapes: ShapeOrPath[] = (shapes as Path[]).concat(holeShapes);

  // 3.
  let colors: number[] = [];
  let sizes: number[] = [];

  for (let x = 0; x < allShapes.length; x++) {
    let shape = allShapes[x];

    const amountPoints = shape.type === "Path" ? data.amount / 2 : data.amount;

    let points = shape.getSpacedPoints(amountPoints);

    points.forEach((element: Vector2, z: number) => {
      const a = new Vector3(element.x, element.y, 0);
      thePoints.push(a);
      colors.push(color.r, color.g, color.b);
      sizes.push(1);
    });
  }

  // 2.
  useLayoutEffect(() => {
    if (textBufferGeometryRef && textBufferGeometryRef.current) {
      const geometry = textBufferGeometryRef.current!;
      geometry.setFromPoints(thePoints);
      geometry.translate(xMid, yMid, 0);
    }
  });

  return (
    <>
      <bufferGeometry
        ref={textBufferGeometryRef}
        attach="geometry"
        attributes={{
          size: new Float32BufferAttribute(sizes, 1),
          customColor: new Float32BufferAttribute(colors, 3),
        }}
      ></bufferGeometry>
      <shaderMaterial
        attach="material"
        vertexShader={`attribute float size;
            attribute vec3 customColor;
            varying vec3 vColor;

            void main() {
              vColor = customColor;
              vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
              gl_PointSize = size * ( 300.0 / -mvPosition.z );
              gl_Position = projectionMatrix * mvPosition;
            }`}
        fragmentShader={`uniform vec3 color;
            uniform sampler2D pointTexture;
            varying vec3 vColor;

            void main() {
              gl_FragColor = vec4( color * vColor, 1.0 );
              gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
            }`}
        uniforms={{
          color: { value: new Color(0xffffff) },
          pointTexture: { value: particle },
        }}
        blending={AdditiveBlending}
        depthTest={false}
        transparent={true}
      ></shaderMaterial>
    </>
  );
};

export default Text;
