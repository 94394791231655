import { useLoader } from "@react-three/fiber";
import { forwardRef, useMemo, useRef } from "react";
import { BufferGeometry, Color, Points, TextureLoader } from "three";
import { Font, FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextParticlesConfig } from "../../types";
import Text from "../Text/Text";

// import paris2024 from "../../assets/fonts/paris2024.blob";
interface ParticlesProps {
  data: TextParticlesConfig;
}

const Particles = forwardRef<Points, ParticlesProps>(({ data }, ref) => {
  // 1. Setup
  const font = useLoader(
    FontLoader,
    require("../../assets/fonts/paris2024.blob")
    // "https://res.cloudinary.com/dydre7amr/raw/upload/v1612950355/font_zsd4dr.json"
  ) as Font;
  const particle = useLoader(
    TextureLoader,
    "https://res.cloudinary.com/dfvtkoboz/image/upload/v1605013866/particle_a64uzf.png"
  );
  const initialColor = useMemo(() => new Color(), []);

  return (
    <points ref={ref}>
      <Text
        font={font}
        particle={particle}
        color={initialColor}
        data={data}
      ></Text>
    </points>
  );
});

export default Particles;
