import { useFrame, useThree } from "@react-three/fiber";
import { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import { BufferGeometry, Color, Mesh, PerspectiveCamera, Points } from "three";
import useWindowSize from "../../hooks/useWindowSize";
import { TextParticlesConfig } from "../../types";
import * as Utils from "../../utils/Utils";
import Particles from "../Particles/Particles";
interface AreaProps {}

export const Area: FC<AreaProps> = () => {
  const { raycaster, camera } = useThree();
  const planeArea = useRef<Mesh>(null);
  const particles = useRef<Points>(null);
  const [data, setData] = useState<TextParticlesConfig>({
    text: "Bientôt",
    amount: 1500,
    particleSize: 1,
    particleColor: 0xffffff,
    textSize: 10,
    area: 250,
    ease: 0.05,
  });
  const [buttom, setButtom] = useState<boolean>(false);
  const geometryCopy = useMemo(() => new BufferGeometry(), []);
  const colorChange = useMemo(() => new Color(), []);

  useLayoutEffect(() => {
    geometryCopy.copy(particles.current!.geometry);
  });

  const magic = () => {
    const time = ((0.001 * performance.now()) % 12) / 12;
    const zigzagTime = (1 + Math.sin(time * 2 * Math.PI)) / 6;

    const intersects = raycaster.intersectObject(planeArea.current!);

    if (intersects.length > 0) {
      const pos = particles.current!.geometry.attributes.position;
      const copy = geometryCopy.attributes.position;
      const colors = particles.current!.geometry.attributes.customColor;
      const size = particles.current!.geometry.attributes.size;

      const mx = intersects[0].point.x;
      const my = intersects[0].point.y;
      // const mz = intersects[0].point.z;

      for (var i = 0, l = pos.count; i < l; i++) {
        const initX = copy.getX(i);
        const initY = copy.getY(i);
        const initZ = copy.getZ(i);

        let px = pos.getX(i);
        let py = pos.getY(i);
        let pz = pos.getZ(i);

        colorChange.setHSL(0.5, 1, 1);
        colors.setXYZ(i, colorChange.r, colorChange.g, colorChange.b);
        colors.needsUpdate = true;

        (size.array as Array<number>)[i] = data.particleSize;
        size.needsUpdate = true;

        let dx = mx - px;
        let dy = my - py;
        // const dz = mz - pz;

        const mouseDistance = Utils.distance(mx, my, px, py);
        const d = (dx = mx - px) * dx + (dy = my - py) * dy;
        const f = -data.area / d;

        if (buttom) {
          const t = Math.atan2(dy, dx);
          px -= f * Math.cos(t);
          py -= f * Math.sin(t);

          colorChange.setHSL(0.5 + zigzagTime, 1.0, 0.5);
          colors.setXYZ(i, colorChange.r, colorChange.g, colorChange.b);
          colors.needsUpdate = true;

          if (
            px > initX + 70 ||
            px < initX - 70 ||
            py > initY + 70 ||
            py < initY - 70
          ) {
            colorChange.setHSL(0.15, 1.0, 0.5);
            colors.setXYZ(i, colorChange.r, colorChange.g, colorChange.b);
            colors.needsUpdate = true;
          }
        } else {
          if (mouseDistance < data.area) {
            if (i % 5 === 0) {
              const t = Math.atan2(dy, dx);
              px -= 0.03 * Math.cos(t);
              py -= 0.03 * Math.sin(t);

              colorChange.setHSL(0.15, 1.0, 0.5);
              colors.setXYZ(i, colorChange.r, colorChange.g, colorChange.b);
              colors.needsUpdate = true;

              (size.array as Array<number>)[i] = data.particleSize / 1.2;
              size.needsUpdate = true;
            } else {
              const t = Math.atan2(dy, dx);
              px += f * Math.cos(t);
              py += f * Math.sin(t);

              pos.setXYZ(i, px, py, pz);
              pos.needsUpdate = true;

              (size.array as Array<number>)[i] = data.particleSize * 1.3;
              size.needsUpdate = true;
            }

            if (
              px > initX + 10 ||
              px < initX - 10 ||
              py > initY + 10 ||
              py < initY - 10
            ) {
              colorChange.setHSL(0.15, 1.0, 0.5);
              colors.setXYZ(i, colorChange.r, colorChange.g, colorChange.b);
              colors.needsUpdate = true;

              (size.array as Array<number>)[i] = data.particleSize / 1.8;
              size.needsUpdate = true;
            }
          }
        }

        px += (initX - px) * data.ease;
        py += (initY - py) * data.ease;
        pz += (initZ - pz) * data.ease;

        pos.setXYZ(i, px, py, pz);
        pos.needsUpdate = true;
      }
    }
  };
  useFrame(() => {
    magic();
  });

  const onPointerDown = () => {
    setButtom(true);
    setData({
      ...data,
      ease: 0.01,
    });
  };

  const onPointerUp = () => {
    setButtom(false);
    setData({
      ...data,
      ease: 0.05,
    });
  };
  return (
    <>
      <mesh
        ref={planeArea}
        visible={false}
        onDoubleClick={(e) => onPointerDown()}
        onPointerUp={(e) => onPointerUp()}
      >
        <planeGeometry
          attach="geometry"
          args={[
            Utils.visibleWidthAtZDepth(100, camera as PerspectiveCamera),
            Utils.visibleHeightAtZDepth(100, camera as PerspectiveCamera),
          ]}
        ></planeGeometry>
        <meshBasicMaterial
          attach="material"
          color={0x00ff00}
          transparent={true}
        ></meshBasicMaterial>
      </mesh>
      <Particles ref={particles} data={data}></Particles>
    </>
  );
};
